// @import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");

// @font-face {
//   // font-family: "Lato";
//   // font-display: swap;
//   // font-style: normal;
//   // src: url("./fonts/Lato-Bold.ttf") format("truetype"); /* IE9 Compat Modes */
//   // src: url("./fonts/Lato-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
// }
:root {
  // --primary-color: #0069b5;
  // --secondary-color: #13c2f3;
  // --hover-color: #e6eef5;
  // --base-color: #ffffff;
  // --base-color-darker: #f7f7f7;
  // --base-secondary-color: #1c262d;
  // --error-color: #e7495d;
}
.sr-only {
  display: none;
}
.text-test {
  // color: var(--primary-color);
}

$SecondaryColor: #0069b5;
//$PrimaryColor: #0b87e7;
$PrimaryColor: #428ed8;
$PrimaryColorLight: #0b88e750;
$ThirdColor: #89cb3f;
$HoverColor: #e6eef5;
$BaseColor: #ffffff;
$BaseColorDarker: #f6f6f6;
$BaseColorHeaders: #f7f7f7;
$ErrorColor: #e7495d;
$BaseSecondaryColor: #1c262d;
$BaseSecondaryColorDarker: #141c21;
$ShadowColor: #1c262d;
$ScrollColor: $HoverColor;
$ButtonColor: var(--primary-color);
$ProblemReport: #e7495d;
$TopBorder: #ececec;
$InputColor: #eef2f5;
$BorderColor: #e2e2e2;
$InsideBorderColor: #838383;
$LabelColor: #2b384b;
$LabelSecondaryColor: #6c757d;
$XlSDoneStatusColor: #44711a;
$XlSOnReviewStatusColor: #28353b;
$TableInterColor: #f7f7f7;
//license manager
$SStatusAvailable: #2fad74;
$SStatusInactive: #d1d1d1;
$DeActivatedLicense: #eeeeee;
$BackgroundItem: #f6f6f6;

$Valid: $ThirdColor;
$Expired: $ErrorColor;

$Registered: $BaseSecondaryColorDarker;
$Captured: #f29100;
$Expected: $SecondaryColor;
$Fake: $ErrorColor;
$Confirmed: $PrimaryColor;
$Activated: $ThirdColor;
$big-font-size: 20px;
$h1-size: 18px;
$h2-size: 15px;
$small-size: 12px;
$normal-size: 15px;
$tooltip-size: 12px;
$small-bold-size: 12px;
$small-h1-size: 15px;

$Shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

$font-400-weight: 400;
$font-700-weight: 700;

$FontFamily: 'Roboto', sans-serif;

h5,
h4,
h3,
h2,
h1,
p,
body {
  font-family: $FontFamily;
}
body {
  min-width: 800px;
}
@mixin h1-style {
  font-size: $h1-size;
  font-weight: $font-400-weight;
  font-family: $FontFamily !important;
  color: black;
}

@mixin h2-style {
  font-size: $h2-size;
  font-weight: $font-700-weight !important;
  font-family: $FontFamily !important;
  color: black;
}

h2 {
  @include h2-style;
  margin: 0;
}

h3 {
  @include h2-style;
  margin: 0;
}
@mixin normal-style {
  font-size: $normal-size;
  font-weight: $font-400-weight;
  font-family: $FontFamily !important;
  color: black;
}
h4 {
  @include normal-style;
  margin: 0;
}
input {
  font-size: $normal-size;
  font-weight: $font-400-weight !important;
  font-family: $FontFamily !important;
  font: $font-400-weight $normal-size $FontFamily;
  color: black;
}
@mixin small-style {
  font-size: $small-size;
  font-weight: $font-400-weight;
  font-family: $FontFamily !important;
  color: black;
}

@mixin small-bold-style {
  font-size: $small-bold-size;
  font-weight: $font-700-weight;
  font-family: $FontFamily !important;
  color: black;
}

@mixin small-h1-style {
  font-size: $small-h1-size;
  font-weight: $font-400-weight;
  font-family: $FontFamily !important;
  color: black;
}

@mixin tooltip-style {
  font-size: $tooltip-size;
  font-weight: $font-400-weight;
  font-family: $FontFamily !important;
  color: black;
}

:root {
  @include normal-style;
}

@mixin scroll {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    //border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-4 {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-4-height {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 4px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-auto-2 {
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 2px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-auto-none {
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 2px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-auto-10 {
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

@mixin scroll-none {
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border-bottom-right-radius: 0px;
  }

  &::-webkit-scrollbar-track {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $ScrollColor;
  }
}

.title-data {
  @include h1-style;
  font-size: 16px !important;
}
.subtitle-data {
  @include normal-style;
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}
.dflex {
  display: flex;
}
.jend {
  justify-content: flex-end;
}
.jstart {
  justify-content: flex-start;
}
.jcenter {
  justify-content: center;
}
.jsbetween {
  justify-content: space-between;
}
.jselfend {
  justify-self: flex-end;
}
.aligcenter {
  align-items: center;
}
.aligstart {
  align-items: flex-start;
}
.aligend {
  align-items: flex-end;
}
