@import '../../assets/variables.scss';

.training-list-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  .tab-search-field {
    display: none;
  }
  .training-filters-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 1em 2em 1em;
    //margin-top: 61px;
    height: 70px;
    .count-container {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      height: 47px;
      flex-wrap: wrap;
      .count {
        display: flex;
        margin: 0;
        padding: 0;
        @include h2-style;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 20px;
        word-break: break-word;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
    .filters-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      .status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px 0;
        button {
          min-width: 150px;
          //margin-right: 1em;
        }
        .label {
          display: flex;
          margin: 0;
          padding: 0;
          @include h2-style;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 20px;
          margin-right: 0.5em;
        }
        .status-group {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.item-list-container {
  padding: 0 15px 0;
  .training-list {
    margin: 0;
    .training-list-item {
      padding: 5px;
      .training-list-item-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 150px;
        border-radius: 5px;
        background-color: $BaseColor;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
        .picture-container {
          height: 100px;
          width: 100px;
          padding-top: 10px;
          padding-left: 5px;
          padding-right: 0px;
          .picture {
            width: 100%;
            height: auto;
            border-radius: 5px;
            max-height: 75px;
            object-fit: cover;
          }
        }
        .details-container {
          height: 100%;
          width: 100%;
          padding-top: 10px;
          padding-left: 10px;
          padding-right: 0px;
          cursor: pointer;
          .detail-info {
            .title {
              text-align: left;
              height: 24px;
              color: #000000;
              font-size: 17px;
              font-weight: 500;
              letter-spacing: 0.37px;
              line-height: 24px;
            }
            .list-unstyled {
              text-align: left;
              padding-top: 25px;
              .pre {
                color: #000000;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.3px;
                line-height: 24px;
              }
              .post {
                opacity: 0.7;
                color: #000000;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.3px;
                line-height: 24px;
              }
            }
          }
        }
        .trash-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          padding-bottom: 10px;
          width: 40px;
          &:hover {
            .btn-delete {
              display: block;
            }
          }
          .btn-delete {
            display: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.top-bar-container {
  background-color: $BaseColor;
  height: 60px;
  margin-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  .top-bar-container-section {
    margin-top: 15px;
    .courses-qty {
      position: relative;
      top: 15px;
      left: 20px;
      float: left;
      color: $ShadowColor;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 14px;
    }
    .btn-add-training {
      position: relative;
      top: 5px;
      right: 20px;
      float: right;
      height: 28px;
      line-height: 14px;
      min-width: 132px;
      border-radius: 3px;
      background-color: $PrimaryColor;
      color: $BaseColor;
    }
  }
}
