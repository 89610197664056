@import '../../assets/variables.scss';

.bingo-box {
  height: 100%;
  .layout-body {
    height: calc(100% - 50px);
  }
  .top-bar-bingo {
    height: 50px;
    background-color: $BaseColor;
    border-bottom: 1px solid $BorderColor;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .selector-bingo {
      height: 40px;
      width: fit-content;
      cursor: pointer;
      margin-left: 15px;
      padding-left: 0.5em;
      padding-right: 10px;
      border-radius: 5px;
      &:hover {
        background-color: $HoverColor;
      }
      svg {
        fill: $BaseSecondaryColor !important;
      }

      h2 {
        font-weight: $font-400-weight !important;
        span {
          font-weight: $font-700-weight !important;
        }
      }
      .btn-group {
        height: 100%;
      }
      .btn {
        height: 100%;
        background-color: transparent;
        border: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 0 0 0rem rgb(72, 180, 97) !important;
        &:hover {
          color: $BaseSecondaryColor;
        }
      }
      .dropdown-toggle::after {
        border: 0 !important;
      }
      .item {
        padding: 1em;
        padding-top: 0.5em;
        .selector-box {
          margin-top: 10px;
        }
        .btn-box {
          margin-top: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .btn {
            color: $BaseColor;
            background-color: $PrimaryColor;
          }
        }
      }
    }
  }
  .point-bingo-item {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BaseColor;
    border-radius: 5px;
    border: 1px solid $BorderColor;
    margin: 0.2em;
    position: relative;
    .triangle {
      border-top: 10px solid $SStatusAvailable;
      border-left: 10px solid transparent;
      position: absolute;
      top: 0;
      right: 0;
    }
    &.bg-green-point {
      background-color: #2fad7434;
    }
    &.bg-grey-point {
      background-color: #6c757d2a;
    }
    svg {
      height: 30px;
      width: 30px;
    }
  }
  .grid-bingo {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1em;
    padding-bottom: 2em;
    padding-top: 0.5em;
    max-height: calc(90vh - 50px);
    @include scroll-none;
  }
  .info-box {
    width: 350px;
    min-height: 350px;
    max-width: 350px;
    height: 100%;
    background-color: $BaseColor;
    border-left: 1px solid $BorderColor;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title-box {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1em;
    }
    .symbols-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $BaseColorHeaders;
      .divider-title {
        background-color: $BaseColor;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.2em;
        padding-left: 1em;
        border-bottom: 1px solid $BorderColor;
        p {
          margin: 0;
          font-weight: $font-700-weight;
        }
      }
      .item {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5em;
        padding-left: 1em;
        .point-bingo-item {
          height: 30px;
          width: 30px;
          min-width: 30px;
          min-height: 30px;
          margin: 0;
          margin-right: 10px;
          font-size: 12px;
        }
        .symbol-icon {
          height: 30px;
          width: 30px;
          border-radius: 5px;
          margin-right: 5px;
          background-color: $BaseColor;
          border: 1px solid $BorderColor;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            height: 15px;
            width: 15px;
          }
        }
        p {
          margin: 0;
          text-align: left;
        }
      }
    }
    .stats-box {
      width: 100%;
      padding: 1em;
      .item {
        &:first-of-type {
          h2 {
            font-size: 22px;
          }
        }
        .positive {
          color: $ErrorColor;
        }
        .negative {
          color: $ThirdColor;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $BorderColor;
        width: 100%;
        height: 40px;
        p {
          margin: 0;
          text-align: left;
          font-size: 12px;
        }
        h2 {
          font-size: 16px;
        }
      }
      .item-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-right: 20px;
        span {
          color: $ProblemReport;
          margin-right: 2px;
        }
        p {
          margin: 0;
          font-size: 10px;
          text-align: left;
        }
      }
    }
  }
}
