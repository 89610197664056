@import '../../assets/variables.scss';

$okColor: #629a2f;
$wrongColorBg: #e67a87;
$wrongColor: #bf192d;
$defaultColor: $InsideBorderColor;

// .global-status-main-container {
//   position: relative;
//   top: 20px;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   width: 100%;
//   height: 0px;
//   z-index: 1;
//   .overlay-status {
//     position: relative;
//     bottom: 18px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     height: 0px;
//     background-color: $BorderColor;
//     z-index: 2;
//     .registered-item, .captured-item, .audited-item {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       width: 100%;
//       height: auto;
//       padding: 0 5px 0px;
//       z-index: 3;
//       .content {
//         width: 10px;
//         height: 10px;
//         border: 1px solid $BorderColor;
//         border-radius: 50%;
//         background-color: $BorderColor;
//         z-index: 4;
//         .title {
//           color: $defaultColor;
//           font-size: 18px;
//           letter-spacing: 0;
//           line-height: 25px;
//           z-index: 5;
//         }
//       }
//       &.is-ok {
//         .content {
//           background-color: $okColor;
//           border-color: $okColor;
//           .title {
//             position: relative;
//             top: 0px;
//             width: 18px;
//             height: 18px;
//             color: $BaseColor;
//           }
//         }
//       }
//       &.is-wrong {
//         .content {
//           border: 1px solid $wrongColor;
//           background-color: $wrongColorBg;
//           .title {
//             color: $wrongColor;
//           }
//         }
//       }
//     }
//   }
//   .overlay-status-filler {
//     position: absolute;
//     bottom:  17px;
//     left: 6px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     height: 4px;
//     z-index: 1;
//   }
//   .overlay-status-filler-base {
//     position: absolute;
//     bottom:  17px;
//     left: 6px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     width: 100px;
//     height: 5px;
//     background-color: $BorderColor;
//     z-index: 0;
//   }
//   .status-content {
//     position: relative;
//     bottom: 20px;
//     right: 10px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     .status-type {
//       margin: 0px;
//       padding: 0px;
//       font-weight: bold;
//     }
//   }

// }

// new Status component

.overlay-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  width: 100%;
  .captured-item,
  .audited-item,
  .registered-item {
    min-width: 90px;
    z-index: 3;
  }
}
.global-status-main-container {
  position: relative;
  display: flex;
  justify-content: center;

  .overlay-status-filler-base {
    z-index: 2;
    position: absolute;
    bottom: 21px;
    left: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5px;
    padding: 0 3.3em;
    .overlay-status-filler {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 4px;
      z-index: 1;
      width: 50%;
    }
  }
}

.icon-status-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  svg {
    height: 25px;
    width: 25px;
  }
  .dot {
    margin-top: 0.2em;
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
  .title {
    @include small-style;
  }
  p {
    margin: 0;
  }
}

.wrong-icon {
  path {
    fill: $wrongColor !important;
  }
}
.ok-icon {
  path {
    fill: $okColor !important;
  }
}
.default-icon {
  path {
    fill: $defaultColor !important;
  }
}
