@import '../../assets/variables.scss';

.dashboard-dia-d {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em;
  .totals-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: $BaseColor;
    width: 100%;
    padding: 1em 2em;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 22px;
      .list-breadcrumb {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .show-link-separator {
          display: block;
        }
        .back-link {
          color: $InsideBorderColor;
          cursor: pointer;
        }
        p {
          margin: 0;
        }
      }
      .tag-time {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $BackgroundItem;
        padding: 0.2em 1em;
        border-radius: 2px;
        p {
          margin: 0;
        }
      }
    }
    .progress-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin-top: 10px;
      height: 52px;
      p {
        margin-bottom: 12px;
      }
      .progress {
        width: 100%;
      }
    }
    .amounts-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 12px;
      .group {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .separator {
        height: 85px;
        border: 1px solid $BorderColor;
      }
      .amount-box {
        //padding: 0.5em 4em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .for-win {
          color: $BaseSecondaryColor;
        }
        .amount-text {
          padding: 0.5em 1em;
          border-radius: 5px;
        }
        /*&:nth-child(2n) {
          border-right: 2px solid $BorderColor;
        }*/
        .title {
          p {
            font-size: 18px;
            margin: 0;
            font-weight: $font-700-weight;
            text-align: center;
            white-space: nowrap;
          }
        }
        .amount {
          text-align: center;
          font-weight: $font-700-weight;
          font-size: 34px;
        }
        .amount-positive {
          color: $SStatusAvailable;
        }
        .span {
          font-size: 14px;
        }
      }
      .timezones-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 2em;
        //padding: 0.5em 0.5em;
        .title {
          margin-left: 20px;
          //padding: 0.2em;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            @include h1-style;
            font-size: 16px;
            font-weight: $font-700-weight;
          }
        }
        .list-timezones {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          height: 100%;
          @include scroll;
          .timezone-item {
            padding: 0.2em;
          }
          .over-time {
            color: #e7495d;
          }
          p {
            text-align: left;
            margin: 0;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .list-docs {
    margin-top: 2em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tab-search-field {
        margin: 0;
      }
    }
    .list {
      margin-top: 20px;
      //   background-color: $BaseColor;
      height: auto;
      position: relative;
      max-height: 500px;
      display: flex;
      justify-content: flex-start;
      align-items: space-between;
      flex-wrap: wrap;
      @include scroll-none;
      // grid
      grid-template-columns: 160px;
      grid-template-rows: 180px;
      column-gap: 10px;
      row-gap: 15px;
    }
  }
  .list-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    height: 180px;
    background-color: $BaseColor;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    p {
      margin: 0;
    }
    .progress-circle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 12px solid rgb(98, 154, 47);
      border-radius: 50px;
      width: 70px;
      height: 70px;
      font-size: 18px;
      font-weight: bold;
    }
    .svg-progress {
      width: 90px;
      height: 90px;
    }
  }
}
// .CircularProgressbar .CircularProgressbar-path {
//   stroke: $SStatusAvailable !important;
// }
.CircularProgressbar .CircularProgressbar-trail {
  stroke: $BackgroundItem !important;
}
.CircularProgressbar {
  text {
    @include h1-style;
    font-weight: $font-700-weight;
    fill: $BaseSecondaryColor !important;
  }
}

.red-bg {
  .progress-bar {
    background-color: #e7495d !important;
  }
}

.orange-bg {
  .progress-bar {
    background-color: #f29100 !important;
  }
}

.green-bg {
  .progress-bar {
    background-color: #2fad74 !important;
  }
}

.gold-bg {
  .progress-bar {
    background-color: #ffd700 !important;
  }
}
