@import '../../assets/variables.scss';

.voter-view-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //align-items: center;
  width: 100%;
  height: 100%;
  padding: 1.5em 2em;
  @include scroll;
  .voter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 1.5em;
    //@include scroll;
    .top-accordion {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #ffffff;
      width: 100%;
      height: 50px;
      padding: 0 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .label-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        h1 {
          @include h1-style;
          font-size: 20px;
          margin: 0;
        }
      }
      .history-box-trigger {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          height: 15px;
          width: 15px;
          margin: 0;
        }
      }
    }
    .body-accordion {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      .voting-information {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 30px;
        width: 100%;
        .information-principal {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 15px 0;
            flex: 1;
            .title {
              text-align: left;
              color: $LabelColor;
              font-size: 15px;
              font-weight: bold;
            }
            .content {
              text-align: left;
              color: $BaseSecondaryColorDarker;
              font-size: 15px;
              margin: 0;
              display: flex !important;
              justify-content: flex-start !important;
              align-items: center;
            }
            &.with-icon {
              .content {
                display: flex;
                justify-content: space-between;
                p {
                  margin: 0;
                }
              }
              .icon {
                width: 20px;
                height: 20px;
                margin-left: 5px;
              }
            }
          }
        }
        .information-secondary {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
          .item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: 15px;
            .title {
              text-align: left;
              color: $LabelColor;
              font-size: 15px;
              font-weight: bold;
            }
            .content {
              text-align: left;
              color: $BaseSecondaryColorDarker;
              font-size: 15px;
              margin: 0;
            }
          }
        }
        .detail-information {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }
      }
      .detail-information {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 30px;
        .detail-fragment {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: calc(32% - 20px);
          .label-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .title {
              font-weight: bold;
              font-size: 15px;
              color: $LabelColor;
              text-transform: uppercase;
              margin: 0;
            }
          }
        }
      }
      .extra-information-photo {
        .information-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          padding: 15px 0;
          .item-with-photo {
            width: calc(50% - 10px);
          }
          .extra-information {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: calc(50% - 10px);
            .content {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              .ine-status-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 100px;
                border: 0.5px solid #1c262d;
                border-radius: 5px;
                letter-spacing: 0.02em;
                margin-bottom: 15px;
                &.valid {
                  background-color: rgba(137, 203, 63, 0.9);
                }
                &.expired {
                  background-color: $Expired;
                }
                p {
                  font-size: 15px;
                  font-weight: 400;
                  font-family: 'Roboto', sans-serif !important;
                  color: #000;
                  color: #fff;
                  margin: 0;
                }
              }
              .ine-exp-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .expired-year {
                  margin: 0;
                  color: #e7495d;
                }
                .valid-year {
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .contact-information {
        .information-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding: 15px 0;
          .with-vote-information {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .title {
              text-align: left;
              font-size: 15px;
              color: $LabelColor;
              margin-bottom: 5px;
            }
            .content {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #1c262d;
              border-radius: 5px;
              padding: 5px;
              width: 60px;
              min-width: 60px;
              margin-bottom: 0;
              .initials {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                font-weight: lighter;
                background-color: #1c262d;
                color: #fff;
                border-radius: 50%;
                min-width: 30px;
                min-height: 30px;
                max-width: 30px;
                max-height: 30px;
                text-transform: uppercase;
              }
            }
          }
          .first-with-vote {
            padding-bottom: 15px;
          }
        }
      }
      .social-information {
        .information-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
          padding: 15px 0;
          .with-social-information {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            svg {
              width: 25px;
              height: 25px;
            }
            .content {
              margin: 0 0 0 10px;
            }
          }
          .first-with-social {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  .visits-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 1.5em;
    @include scroll;
    .top-accordion {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #ffffff;
      width: 100%;
      height: 50px;
      padding: 0 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .label-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        h1 {
          @include h1-style;
          font-size: 20px;
          margin: 0;
        }
      }
      .history-box-trigger {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          height: 15px;
          width: 15px;
          margin: 0;
        }
      }
    }
    .voters-territorial-list {
      height: auto;
      .voter-justify {
        overflow: unset;
        .table-box {
          // max-height: 700px;
          .table-container {
            min-height: auto;
            //height: 100%;
            .table-responsive {
              //height: 100%;
              .table {
                // height: 700px;
                thead {
                  margin-left: 30px;
                  .order-container {
                    min-width: 76px;
                    max-width: 76px;
                    width: 76px;
                  }
                  .date-container {
                    width: 15%;
                  }
                  .visitor-container {
                    width: 30%;
                  }
                  .description-container {
                    width: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .voter-container {
    .label-container {
      text-align: left;
    }
    .information-content {
      .item {
        padding: 0px 0px 0px 15px;
        &.with-firstname {
          align-items: flex-start;
          .content {
            padding-right: 15px;
          }
        }
        // &.with-photo {
        //   .content {
        //     &.with-img {
        //       .photo-id {
        //         max-width: 70%;
        //         max-height: 100%;
        //         border-radius: 10px;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}
@media (max-width: 768px) {
  .voter-container {
    //flex-direction: row;
    .label-container {
      text-align: left;
    }
    .information-principal {
      flex-direction: column;
    }
    .information-content {
      .item {
        &.with-cic {
          align-items: flex-start;
        }
        &.with-firstname {
          align-items: flex-start;
        }
        &.with-icon {
          align-items: flex-start;
          justify-content: flex-start;
          .title {
            text-align: left;
          }
        }
        &.with-date {
          align-items: flex-start;
          .title {
            text-align: left;
          }
        }
        // &.with-photo {
        //   .content {
        //     &.with-img {
        //       .photo-id {
        //         max-width: 50%;
        //         max-height: 100%;
        //         border-radius: 10px;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}
@media (max-width: 414px) {
  .voter-container {
    .label-container {
      text-align: left;
    }
    .information-content {
      .item {
        &.with-icon {
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0px 0px 0px 15px;
          .title {
            text-align: left;
          }
        }
        &.with-date {
          align-items: flex-start;
          .title {
            text-align: left;
          }
        }
        &.with-cic {
          align-items: flex-start;
        }
        &.with-firstname {
          align-items: flex-start;
        }
      }
    }
  }
}
@media (max-height: 812px) {
  /*.voter-container {
    @include scroll-4;
    overflow-y: auto;
    overflow-x: none;
  }*/
}
