@import '../../assets/variables.scss';

.pop-over-timeline {
  top: 10px !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  border-color: $BaseColor;
  border-radius: 10px;
  background-color: #666666;
  text-align: center;
  min-width: 340px;
  .arrow {
    width: 2rem;
    height: 1rem;
    top: calc(-0.6rem - 6px);
    &::before {
      border-width: 0 1rem 1rem 1rem;
      border-bottom-color: #666666;
    }
    &::after {
      border-width: 0 1rem 1rem 1rem;
      border-bottom-color: #666666;
    }
  }
  .title {
    border-radius: 10px;
    background-color: #666666;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    color: $BaseColor;
  }
  .content {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    background-color: #666666;
    font-size: 15px;
    border-radius: 10px;
    color: $BaseColor;
    p {
      margin-bottom: 0.5rem;
    }
  }
}

.timeline__container {
  position: relative;
  height: 50px;
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  .timeline__dates {
    position: initial;
    width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px;
    padding-left: 0px;
    overflow: hidden;
    .timeline__line {
      position: absolute;
      left: 0;
      top: 25px;
      width: 100%;
      height: 3px;
      background-color: hsl(0, 0%, calc(97% * 0.9));
      transition: -webkit-transform 0.4s;
      transition: transform 0.4s;
      transition: transform 0.4s, -webkit-transform 0.4s;
      z-index: 1;
      ol {
        list-style: none;
        margin: 0px;
        padding: 0px;
        border: 0px;
        box-sizing: inherit;
        li {
          margin: 0px;
          padding: 0px;
          border: 0;
          box-sizing: inherit;
          .timeline__date {
            position: absolute;
            bottom: 0;
            text-align: center;
            font-size: 0.8em;
            padding-bottom: calc(0.75 * 1.25em);
            color: $BaseSecondaryColor;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-decoration: none;
            &.timeline__date--selected {
              pointer-events: none;
              &::after {
                background-color: $ThirdColor;
                border-color: $ThirdColor;
              }
            }
            &.timeline__date--older-event {
              &::after {
                background-color: $ThirdColor;
                border-color: $ThirdColor;
              }
            }
            &:focus {
              outline: -webkit-focus-ring-color auto 1px;
            }
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              transform: translateX(-50%);
              bottom: -5px;
              height: 12px;
              width: 12px;
              border-radius: 50%;
              border-width: 7px;
              border-style: solid;
              border-color: $BorderColor;
              background-color: $BorderColor;
              transition: background-color 0.3s, border-color 0.3s;
            }
            &:hover {
              &::after {
                background-color: $ThirdColor;
                border-color: $ThirdColor;
              }
            }
          }
        }
      }
      .timeline__filling-line {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        height: 4px;
        width: 100%;
        background-color: $ThirdColor;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -ms-transform-origin: left center;
        transform-origin: left center;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
      }
    }
  }
}
